import React  from "react";

// componenents
import Layout from "../components/Layout";
import ForgotYourPassword from "../components/forms/ForgotYourPasswordForm"

// Todo:
// - 

// markup
const IndexPage = () => {
  return (
    <Layout>
      <ForgotYourPassword />
    </Layout>
  )
};

export default IndexPage;